import React from 'react';
import styles from './EmailInline.module.css';

interface EmailHeaderProps {
    email: string;
    subject?: string;
    body?: string;
    size?: 'small' | 'medium' | 'large'; 
    color?: string;
}

const EmailInline: React.FC<EmailHeaderProps> = ({
    email, 
    subject,
    body,
    size = 'medium',
    color = '#DBFF80',
}) => {
    let mailToLink = `mailto:${email}`;
    const params = [];

    if (subject) {
        params.push(`subject=${encodeURIComponent(subject)}`);
    }

    if (body) {
        params.push(`body=${encodeURIComponent(body)}`);
    }

    if (params.length > 0) {
        mailToLink += `?${params.join('&')}`;
    }

    const sizeClass = styles[`emailLink_${size}`] || styles.emailLink_medium;

    return (
        <span>
            <a 
                href={mailToLink} 
                className={sizeClass}
                style={
                    {
                        '--color': color,
                    } as React.CSSProperties
                }
            >
                {email}
            </a>
        </span>
    );
};

export default EmailInline;
