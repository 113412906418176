// TextureImage.tsx

import { useState, useEffect } from 'react';
import styles from './TextureImage.module.css';

interface TextureImageProps {
    imgUrlMobile: string;
    imgUrlDesktop?: string;
    alt?: string; // Optional since we're using background-image
    mobileBreakpoint?: number;
    spinnerBorderColor?: string;
    spinnerTopColor?: string;
}

const TextureImage: React.FC<TextureImageProps> = ({
    imgUrlMobile, 
    imgUrlDesktop,
    alt, 
    mobileBreakpoint = 768,
    spinnerBorderColor = '#FFFFFF', 
    spinnerTopColor = '#000000' 
}) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [isMobileScreen, setIsMobileScreen] = useState<boolean>(false);
    const selectedImgUrl = isMobileScreen ? imgUrlMobile : imgUrlDesktop || imgUrlMobile;

    useEffect(() => {
        const handleResize = () => {
            if (typeof window !== 'undefined') {
                setIsMobileScreen(window.innerWidth <= mobileBreakpoint);
            }
        };

        // Initial check
        handleResize();

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, [mobileBreakpoint]);

    useEffect(() => {
        // Preload the selected image
        const img = new Image();
        img.src = selectedImgUrl;
        img.onload = () => setLoading(false);
        img.onerror = () => setLoading(false);
    }, [selectedImgUrl]);

    return (
        <div 
            className={styles.imageWrapper}
            style={{ 
                '--spinner-border-color': spinnerBorderColor,
                '--spinner-top-color': spinnerTopColor,
                '--background-image-url': `url(${selectedImgUrl || '/placeholder.png'})`
            } as React.CSSProperties}
            aria-hidden={!alt ? "true" : "false"} // Hide if alt is not provided
        >
            {loading && (
                <div className={styles.spinner}></div>
            )}
            {/* If the image is informative, include visually hidden text for accessibility */}
            {!loading && alt && (
                <span className={styles.visuallyHidden}>{alt}</span>
            )}
        </div>
    )
};

export default TextureImage;
