import React from 'react';
import PhotoDetail from '../../components/PhotoDetail';
import styles from './InfoCardVideo.module.css';
import CircularVideo from '../../components/CircularVideo';
import IconButton from '../../components/IconButton';
import CircularArrowRightIcon from '../../components/CircularArrowRightIcon';
import { useNavigate } from 'react-router-dom';

interface InfoCardVideoProps {
    headerText: string;
    descriptionText: string;
    video: string;
    altText: string;
    objectPosition?: string;
    headerTextColor?: string;
    descriptionTextColor?: string;
    backgroundColor?: string;
}


const InfoCardVideo: React.FC<InfoCardVideoProps> = ({
    headerText, 
    descriptionText,
    video,
    altText,
    objectPosition = '50% 70%', // dead center
    headerTextColor = "#003600",
    descriptionTextColor = "#003600",
    backgroundColor = '#FAFAF2',
}) => {

    const navigate = useNavigate();

    return (
        <div 
            className={styles.infoCardContainer}
            style={
                {
                    '--background-color': backgroundColor,
                } as React.CSSProperties
            }
        >
            <div
                className={styles.videoContainer}
            >
                <CircularVideo
                    video={video}
                    altText={altText}
                    objectPosition={objectPosition}
                />
            </div>
            <div
                className={styles.textContainer}
            >
                <PhotoDetail
                    headerText={headerText}
                    descriptionText={descriptionText}
                    headerTextColor={headerTextColor}
                    descriptionTextColor={descriptionTextColor}
                />
                <IconButton
                    iconSrc={CircularArrowRightIcon}
                    iconAlt="Circle Arrow Right"
                    text="Sign up"
                    backgroundColor="#003600"
                    textColor = "#DBFF80"
                    hoverBackgroundColor='#DBFF8080'
                    hoverTextColor='#DBFF8080'
                    fontFamily="'visuelt-regular-pro', sans-serif"
                    onClick={() => navigate('/turtltracker')}
                />
            </div>
        </div>
    );
};

export default InfoCardVideo;