import React from 'react';
import styles from './InfoCardText.module.css';
import LeftSkewHeader from '../../components/LeftSkewHeader';
import LeftSkewPar from '../../components/LeftSkewPar';

interface InfoCardTextProps {
    headerText: React.ReactNode;
    descText: string;
    backgroundColor?: string;
    divLineColor?: string;
    headerTextColor?: string;
    descTextColor?: string;
    reverseOnMobile?: boolean;
}

/*  TODO: PICKUP 1.13.2025: ADD LINE ONCE IMAGE RECEIVED, INTEGRATE INTO HOME PAGE, TEST
    THEN MAKE FINAL COMPONENT / WIDGET FOR THE OUR PLATFORM SECTION. SHOULD HAVE A COL COMPONENT
    WHICH TAKES A CIRCULAR PHOTO, HEADER, SMALL UNDERLINE AND DESCRIPTION!! THEN ALL FOUR
    TOGETHER WITH THE CORRECT PROPS AND AN H1 LEFT SKEW HEADER MAKE THE WIDGET! ALSO DON'T
    FORGET OPTIONAL BUTTON FOR INFO CARD IMAGE WIDGET
*/

const InfoCardText: React.FC<InfoCardTextProps> = ({
    headerText,
    descText, 
    backgroundColor = '#FAFAF2',
    divLineColor = '#003600',
    headerTextColor = '#003600',
    descTextColor = '#003600',
    reverseOnMobile = false,
}) => {

    const containerClassNames = `${styles.cardContainer} 
        ${reverseOnMobile ? styles.reverseOnMobile : ''
    }`;

    return (
        <div
            className={containerClassNames}
            style={
                {
                    '--background-color': backgroundColor
                } as React.CSSProperties
            }
        >
            {/* Left header (or top) container */}
            <div
                className={styles.cell}
            >
                <LeftSkewHeader
                    text={headerText}
                    textColor={headerTextColor}
                />
            </div>
            <div 
                className={styles.line}
                style={
                    {
                        '--div-line-color': divLineColor
                    } as React.CSSProperties
                }
            />
            <div
                className={styles.cell}
            >
                <LeftSkewPar
                    text={descText}
                    textColor={descTextColor}
                />
            </div>
        </div>
    );
};

export default InfoCardText;