import React from 'react';
import styles from './PhotoDetail.module.css';

interface PhotoDetailProps {
    headerText: string;
    descriptionText: string;
    headerTextColor?: string;
    descriptionTextColor?: string;
}

const PhotoDetail: React.FC<PhotoDetailProps> = ({
    headerText,
    descriptionText,
    headerTextColor = '#003600',
    descriptionTextColor = '#003600',
}) => {
    return (
        <div>
            <div>
                <h2
                    className={styles.detailHeader}
                    style={
                        {
                            '--header-text-color': headerTextColor,
                        } as React.CSSProperties
                    }
                >
                    {headerText}
                </h2>
            </div>
            <div>
                <p
                    className={styles.detailText}
                    style={
                        {
                            '--desc-text-color': descriptionTextColor,
                        } as React.CSSProperties
                    }
                >
                    {descriptionText}
                </p>
            </div>
        </div>
    );
};

export default PhotoDetail;