import React from 'react';
import styles from './CircularButton.module.css';

interface CircularButtonProps {
    label: number | string;
    backgroundColor: string;
    textColor: string;
    selected?: boolean;
    selectedBackgroundColor?: string;
    selectedTextColor?: string;
    onClick: () => void;
}

function formatThousands(value: number | string): string {
    if (typeof value === 'string') return value;
    if (value < 1000) {
        return `$${value.toString()}`;
    } 
    
    const thousands = Math.floor(value / 1000);
    return `$${thousands}K`;
}

const CircularButton: React.FC<CircularButtonProps> = ({
    label,
    backgroundColor,
    textColor, 
    selected = false, 
    selectedBackgroundColor = '#DBFF80',
    selectedTextColor = '#003600',
    onClick,
}) => {
    const appliedBackgroundColor = selected ? selectedBackgroundColor : backgroundColor;
    const appliedTextColor = selected ? selectedTextColor : textColor;

    const formattedLabel = formatThousands(label);

    return (
        <button
            className={styles.circularButton}
            style={{ backgroundColor: appliedBackgroundColor, color: appliedTextColor}}
            onClick={onClick}
            aria-pressed={selected}
        >
            {formattedLabel}
        </button>
    )
};

export default CircularButton;