import React from 'react';

const CircularArrowRightIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 91.17 91.17"
            width="48px"
            height="48px"
            fill="currentColor"
            {...props}
        >
        {/* Circle as path */}
        <path
            d="
                M45.59,0
                A45.59,45.59 0 0,1 91.18,45.59
                A45.59,45.59 0 0,1 45.59,91.18
                A45.59,45.59 0 0,1 0,45.59
                A45.59,45.59 0 0,1 45.59,0
                Z
            "
        />
        
        {/* Arrow segments as paths (converted from <polyline> and <line>) */}
        <path
            d="M47.64,26.26 L66.97,45.59 L47.64,64.91"
            fill="none"
            stroke="#003600"
            strokeWidth={3.88}
            strokeMiterlimit={10}
        />
        <path
            d="M24.2,45.59 L65.51,45.59"
            fill="none"
            stroke="#003600"
            strokeWidth={3.88}
            strokeMiterlimit={10}
        />
    </svg>
    );
};

export default CircularArrowRightIcon;
