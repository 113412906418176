import React from 'react';
import styles from './LeftSkewPar.module.css';

interface LeftSkewParProps {
    text: string;
    textColor?: string;
}

const LeftSkewPar: React.FC<LeftSkewParProps> = ({
    text, 
    textColor = "#003600",
}) => {
    return (
        <>
            <p
                className={styles.parText}
                style={
                    {
                        '--text-color': textColor
                    } as React.CSSProperties
                }
            >
                {text}
            </p>
        </>
    );
};

export default LeftSkewPar;