import React from 'react';
import PlatformCard from '../PlatformCard';
import styles from './OurPlatformFourCards.module.css';

const OurPlatformFourCards: React.FC = () => {
    return (
        <div
            className={styles.outerContainer}
        >
            <h2 className={styles.heading}> 
                Our platform
            </h2>
            <div
                className={styles.cardsGrid}
            >
                <PlatformCard
                    imgUrlDesktop='https://turtl-web-images.azureedge.net/website/Vision-AI.jpg'
                    imgUrlMobile='https://turtl-web-images.azureedge.net/website/Vision-AI.jpg'
                    altText='Vision AI image'
                    title="Vision AI"
                    description='Leveraging cutting-edge technology, our image recognition system can correctly identify a turtle species from a single fragment from a shattered shell.'
                /> 
                <PlatformCard
                    imgUrlDesktop='https://turtl-web-images.azureedge.net/website/Geocoding-Integration.jpg'
                    imgUrlMobile='https://turtl-web-images.azureedge.net/website/Geocoding-Integration.jpg'
                    altText='Geocoding image'
                    title="Geocoding"
                    description='TurtlTracker seamlessly integrates with your device’s native map platform, ensuring precise tracking and data accuracy.'
                /> 
                <PlatformCard
                    imgUrlDesktop='https://turtl-web-images.azureedge.net/website/Community-Platform.jpg'
                    imgUrlMobile='https://turtl-web-images.azureedge.net/website/Community-Platform.jpg'
                    altText='Community platform image'
                    title="Community"
                    description='Our AI model ensures full inclusivity, empowering everyone to make a meaningful impact through the power of citizen science.'
                /> 
                <PlatformCard
                    imgUrlDesktop='https://turtl-web-images.azureedge.net/website/Path-To-Help.jpg'
                    imgUrlMobile='https://turtl-web-images.azureedge.net/website/Path-To-Help.jpg'
                    altText='Path to help image'
                    title="Path to help"
                    description='Our platform integrates education, technology, and a vibrant social platform — enabling individuals to easily contribute, connect and make a difference.'
                /> 
            </div>
        </div>
    );
};

export default OurPlatformFourCards;