import React, { useState, useEffect } from 'react';
import styles from './TabMenu.module.css';

interface TabItem {
    label: string;
    hash: string;
    heading: string;
    content: React.ReactNode;
}

class TabItem implements TabItem {
    label: string;
    hash: string;
    heading: string;
    content: React.ReactNode;

    constructor(
    label: string,
    hash: string,
    heading: string,
    content: React.ReactNode,
    ) {
        this.label = label;
        this.hash = hash;
        this.heading = heading;
        this.content = content;
    }
}

interface TabMenuProps {
    tabs: TabItem[];
}

const TabMenu: React.FC<TabMenuProps> = ({ tabs }) => {
    const [activeHash, setActiveHash] = useState<string>(tabs[0]?.hash || '');

    useEffect(() => {
        const onHashChange = () => {
            const currentHash = window.location.hash;
            if (tabs.find((t) => t.hash === currentHash)) {
            setActiveHash(currentHash);
            }
        };

        // Listen for hash changes
        window.addEventListener('hashchange', onHashChange);

        // Check initial hash on mount
        onHashChange();

        return () => {
            window.removeEventListener('hashchange', onHashChange);
        };
    }, [tabs]);

    // Generate anchors (clickable tabs in the menu)
    const tabLinks = tabs.map((tab, index) => {
    const isActive = activeHash === tab.hash;
    return (
            <a
                key={index}
                data-w-tab={tab.label}
                // Combine the module class with the active class if needed
                className={`${styles.myTabLink} ${isActive ? styles.active : ''}`}
                id={`tab-link-${index}`}
                href={tab.hash}
                role="tab"
                aria-controls={`panel-${index}`}
                aria-selected={isActive}
                tabIndex={isActive ? 0 : -1}
            >
                <div className={styles.myTabText}>{tab.label}</div>
            </a>
        );
    });

    const tabPanels = tabs.map((tab, index) => {
    const isActive = activeHash === tab.hash;
    return (
        <div
        key={index}
        id={`panel-${index}`}
        className={`${styles.myTabPane} ${isActive ? styles.activePane : ''}`}
        role="tabpanel"
        aria-labelledby={`tab-link-${index}`}
        style={{ display: isActive ? 'block' : 'none' }}
        >
            <p className={styles.tabParText}>
                {tab.content}
            </p>
        </div>
    );
    });

    return (
    <div className={styles.myTabsContainer}>
        {/* The tab list with anchors */}
        <div className={styles.myTabsMenu}>
            <div className={styles.myTabsInner} role="tablist">
                {tabLinks}
            </div>
        </div>
        {/* The tab content panels */}
        <div className={styles.myTabsContent}>
            {tabPanels}
        </div>
    </div>
    );
};

export { TabItem };
export default TabMenu;