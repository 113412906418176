import React from 'react';
import styles from './CircularVideo.module.css';

interface CircularVideoProps {
    video: string;
    altText?: string;
    objectPosition?: string;
}

const CircularVideo: React.FC<CircularVideoProps> = ({
    video,
    altText,
    objectPosition = '50% 80%'
}) => {
return (
<div
    className={styles.videoContainer}
    style={{ '--video-position': objectPosition } as React.CSSProperties}
>
    <video 
        className={styles.circularVideo}
        src={video}
        autoPlay 
        loop 
        playsInline 
        muted 
        aria-label={altText || 'Video content'}
    >

        {altText || 'Your browser does not support the video tag'}
    </video>
</div>
);
};

export default CircularVideo;
