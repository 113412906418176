import styles from './Merch.module.css';
import PRODUCTS, { Product } from './data/products';
import ProductContainer from '../../components/ProductContainer';
import { useNavigate } from 'react-router-dom';


const Merch: React.FC = () => {
    const navigate = useNavigate();

    return (
        <div className={styles.container}>
            <h1 className={styles.merchHeader}>
                Merch
            </h1>
            <p className={styles.merchDescription}>
                Show off your TurtlTracker support seasonally with our small yet
                statement-making winter line. Proceeds support the launch of
                TurtlTracker.
            </p>
            <div className={styles.productGrid}>
                {PRODUCTS.map(product => (
                    <div key={product.id} className={styles.productCard}>
                        <ProductContainer
                            product={product}
                            onClick={() => navigate(`/merch/item/${product.id}`)}
                            showAddToCart={false}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Merch;