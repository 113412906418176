import React from 'react';
import styles from './Banner.module.css';
import { Link } from 'react-router-dom'

interface BannerMessage {
    text: string;
    url?: string;
}

interface BannerProps {
    messages: BannerMessage[];
    imageSrc: string;
}

const Banner: React.FC<BannerProps> = ({
    messages,
    imageSrc,
}) => {

    /*    TODO: PICKUP 1.14.2025: FINISH BANNER, CENTER PAR TEXT UNDER TAB MENU PROPERLY, 
    FIX IMAGE TEXTURE BUG AND MAKE SURE IT FILLS THE ENTIRE SCREEN. ADD BUTTON AS OPTIONAL TO VIEW IN THE IMAGE INFO
    CARD FOR THE FINAL GET TURTL TRACKER BUTTON, ADD REMAINING COMPONENTS AND WIDGETS FOR THE OUR PLATFORM SECTION, 
    UPDATE ALL FONTS!! */ 

    const repeatedMessages = [
                        ...messages, 
                        ...messages, 
                        ...messages,
                        ...messages,
                        ...messages,
                        ...messages,
                        ...messages,
                        ...messages,
                        ...messages,
                    ];

    return (
        <div className={styles.banner}>
            <div className={styles.marquee}>
                <div className={styles.marqueeContent}>
                {repeatedMessages.map((msg, idx) => (
                    <React.Fragment key={idx}>
                        {renderMessage(msg)}
                            <img
                            src={imageSrc}
                            alt="turtle separator"
                            className={styles.image}
                        />
                    </React.Fragment>
                    ))}
            </div>
        </div>
    </div>
    );
};


const renderMessage = (message: BannerMessage) => {
    if (message.url) {
        return (
            <Link
                to={message.url}
                className={styles.link}
            >
                {message.text}
            </Link>
        );
    } else {
        // if there isn't a message url link, just return the plane text
        return <span className={styles.text}>{message.text}</span>;
    }
};


export default Banner;


