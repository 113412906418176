import axios from 'axios';

const uri = process.env.REACT_APP_API_URI;

export const sendPutRequest = async(path: string, bodyContent: any): Promise<any> => {
    const url = uri + path;
    console.log('PUT base url:', url); 

    try {
        const resp = await axios.put(url, bodyContent, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'text/plain'
            },
        })
        return resp;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const sendPostRequest = async(path: string, bodyContent: any): Promise<any> => {
    const url = uri + path;
    console.log('POST base url:', url);
    console.log('body content', bodyContent);

    try {
        const resp = await axios.post(url, bodyContent, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'text/plain'
            },
        })
        console.log('POST response', JSON.stringify(resp));
        return resp;
    } catch (error) {
        console.error('Error', error);
        throw error;
    }
};

export const sendGetRequest = async(path: string) => {
    const url = uri + path;
    console.log('GET url', url);

    try {
        const resp = await fetch(url);
        if (!resp.ok) {
            throw new Error('Failed to fetch SAS URL');
        }
        const data = await resp.json();
        return data;
    } catch(err) {
        console.error(`Error with GET request ${err}`);
    }
};;
