import React from 'react';
import styles from './AddToCartModal.module.css';
import { Product } from '../../pages/Merch/data/products';
import ArrowRightIcon from '../ArrowRightIcon';
import IconButton from '../IconButton';
import ProductContainer from '../ProductContainer';
import { useNavigate } from 'react-router-dom';

interface AddToCartModalProps {
    isOpen: boolean;
    similarProducts: Product[];
    onClose: () => void;
    onCheckout: () => void;    
}

const AddToCartModal: React.FC<AddToCartModalProps> = ({
    isOpen,
    similarProducts,
    onClose,
    onCheckout,
}) => {

    const navigate = useNavigate();

    const handleClick = (productId: string) =>  {
        navigate(`/merch/item/${productId}`);
        onClose();
    };

    if (!isOpen) return null;

    const handleOverlayClick = () => {
        onClose();
    };

    const handleContentClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
    };

    return (
    <div className={styles.modalOverlay} onClick={handleOverlayClick}>
        <div className={styles.modalContent} onClick={handleContentClick}>
            <button className={styles.closeButton} onClick={onClose}>
                &times; 
            </button>

            <h2 className={styles.merchHeader}>
                You've got great taste
            </h2>
            <p className={styles.merchDescription}>
                Item added to your cart.
            </p>
            
            <IconButton
                    iconSrc={ArrowRightIcon}
                    iconAlt="Arrow Right"
                    text="View cart & checkout"
                    backgroundColor="#FAFAF2"
                    hoverBackgroundColor='#FAFAF2'
                    hoverTextColor='#003600'
                    textColor="#003600"
                    fontFamily="'Work Sans', sans-serif"
                    onClick={onCheckout}
                />
            <div className={styles.similarProductsSection}>
                <h3 className={styles.subHeader}>
                    Goes well with
                </h3>
                <div className={styles.similarProductsGrid}>
                    {similarProducts.map((product) => (
                        <div key={product.id} className={styles.similarProductCard}>
                            <ProductContainer
                                product={product}
                                onClick={() => handleClick(product.id)}
                                showAddToCart={false}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </div>
    );
};

export default AddToCartModal;
