import { Product } from "./products";
import blackHatRecos from "./ProductRecommendations/blackHatRecos";
import creamCrewRecos from "./ProductRecommendations/creamCrewRecos";
import greenCrewRecos from "./ProductRecommendations/greenCrewRecos";   
import sageHatRecos from "./ProductRecommendations/sageHatRecos";
import wheatHatRecos from "./ProductRecommendations/wheatHatRecos";
import whiteHatRecos from "./ProductRecommendations/whiteHatRecos";

const productRecoMap: Map<string, Product[]> = new Map();

productRecoMap.set('prod_RPGHVBvdsSDAPO', wheatHatRecos);
productRecoMap.set('prod_RPGGi55hXAWWs7', sageHatRecos);
productRecoMap.set('prod_RPGAL8SZp7lIM8', blackHatRecos);
productRecoMap.set('prod_RPG79AY9CfAPyn', whiteHatRecos);
productRecoMap.set('prod_ROA0rvO0Q0yXAi', creamCrewRecos);
productRecoMap.set('prod_ROA39UFFrPsUOy', greenCrewRecos);

export default productRecoMap;
