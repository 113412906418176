import {useState } from 'react';
import styles from './LoadedImage.module.css';

interface LoadedImageProps {
    imgUrlMobile: string;
    imgUrlDesktop?: string;
    alt: string;
    mobileBreakpoint?: number;
    imageWrapperStyle?: React.CSSProperties;
    imageStyle?: React.CSSProperties;
    spinnerBorderColor?: string;
    spinnerTopColor?: string;
}

const LoadedImage: React.FC<LoadedImageProps> = ({
    imgUrlMobile, 
    imgUrlDesktop,
    alt, 
    mobileBreakpoint = 768,
    imageWrapperStyle,
    imageStyle,
    spinnerBorderColor = '#FFFFFF', 
    spinnerTopColor = '#000000' 
}) => {
    const [loading, setLoading] = useState<boolean>(true);
    const isMobileScreen = typeof window !== 'undefined' && window.innerWidth <= mobileBreakpoint;
    const selectedImgUrl = isMobileScreen ? imgUrlMobile : imgUrlDesktop;


    const handleImageLoad = () => setLoading(false);
    const handleImageError = () => setLoading(false);

    return (
        <div 
            style={{ ...imageWrapperStyle}}>
            {loading && (
                <div
                    className={styles.spinner}
                    style={{
                        border: `0.25rem solid ${spinnerBorderColor}`,
                        borderTopColor: spinnerTopColor
                    }}
                >
                </div>
            )}
            <img
                key={selectedImgUrl}
                src={selectedImgUrl || '/placeholder.png'}
                alt={alt}
                onLoad={handleImageLoad}
                onError={handleImageError}
                style={{ 
                    display: loading ? 'none' : 'block',
                    ...imageStyle 
                }}
            />
            </div>
    )
};

export default LoadedImage;
