import React from 'react';
import styles from './HomePage.module.css';
import VideoBox from '../../components/VideoBox';
import TABS_DATA from './data/tabsData';
import InfoCardImage from '../../widgets/InfoCardImage'; 
import InfoCardText from '../../widgets/InfoCardText';
import OurPlatformFourCards from '../../widgets/OurPlatformFourCards';
import TabMenu from '../../components/TabMenu';
import InfoCardVideo from '../../widgets/InfoCardVideo';
import TextureImage from '../../components/TextureImage';

const HomePage: React.FC = () => {
    return (
        <div className={styles.homePage}>
            <div>
                <VideoBox
                    video={'https://turtl-web-images.azureedge.net/website/musk-turtl-road-crossing.mp4'}
                    altText='Musk Turtle Crosses The Road'
                    overlayText={
                        <>
                            It is estimated that one million animals are hit by cars every day.
                            <sup>1</sup>
                        </>
                    }
                />
            </div>
            <div>
                <InfoCardText
                    headerText={
                        <>
                            <span 
                                style={{ color: '#DBFF80' }}
                            >
                                60%
                            </span> 
                            {'   '} of turtle species are endangered or threatened
                        </>
                    }
                    descText={
                        `Population loss from road mortality is a leading cause. 
                        There is little awareness or attention to this issue, 
                        as turtles are generally considered "common" or dispensable animals.`
                    }
                    backgroundColor='#003600'
                    divLineColor='#DBFF80'
                    headerTextColor='#FAFAF2'
                    descTextColor='#FAFAF2'
                />
            </div>
            <div className={styles.imageContainer}>
                <TextureImage
                        imgUrlMobile='https://turtl-web-images.azureedge.net/website/Texture-2.jpg'
                        imgUrlDesktop='https://turtl-web-images.azureedge.net/website/Texture-2.jpg'
                        alt='Image of turtle shell pattern'
                />
            </div>
            <div>
                <InfoCardImage
                        headerText='Want a biodiverse world that has turtles in it? Us too.'
                        descriptionText={
                            `A problem this big and this urgent requires a community 
                            to come together to help solve it. TurtlTracker is an AI-powered app 
                            that enables anyone to contribute accurate data with a
                            single photo of a turtle.
                            `
                        }
                        imgUrlMobile='https://turtl-web-images.azureedge.net/website/Sea-Turtle.jpg'
                        imgUrlDesktop='https://turtl-web-images.azureedge.net/website/Sea-Turtle.jpg'
                        altText='Photo of a sea turtle'
                />
            </div>
            <div className={styles.imageContainer}>
                <TextureImage
                        imgUrlMobile='https://turtl-web-images.azureedge.net/website/Texture-3.jpg'
                        imgUrlDesktop='https://turtl-web-images.azureedge.net/website/Texture-3.jpg'
                        alt='Image of the ocean and shore'
                />
            </div>
            <div>
                <OurPlatformFourCards/>
            </div>
            <div className={styles.tabHeaderWrapper}>
                <h1 className={styles.tabMenuHeader}>
                    Who we help
                </h1>
            </div>
            <div className={styles.tabMenuContainer}>
                <TabMenu
                    tabs={TABS_DATA}
                />
            </div>
            <div>
                <InfoCardVideo
                    headerText='Our beta launches in the spring of 2025.'
                    descriptionText={
                        `Be a part of the solution. Join us in uncovering movement, 
                        migration, and mortality trends to pave the way for a turtle-friendly future`
                    }
                    video='https://turtl-web-images.azureedge.net/website/our_beta_launches.mp4'
                    altText='Video of turtl tracker logo'
                    headerTextColor='#DBFF80'
                    descriptionTextColor='#FAFAF2'
                    backgroundColor='#003600'
                />
            </div>
            <div 
                className={styles.citationsContainer}
            >
                <p
                    className={styles.citationsText}    
                >
                    1.
                    <a
                        href="https://www.bengoldfarb.com/crossings"
                        className={styles.citationLink}
                        target="_blank" 
                        rel="noopener noreferrer"
                    >
                        {'  '}Crossings, Chapter 1: Goldfarb, B. (2023). <em>Crossings: How Road Ecology Is Shaping the Future of Our Planet.</em>{'  '}
                    </a>
                    Chapter 1. W.W. Norton & Company.
                </p>
                <p
                    className={styles.citationsText}    
                >
                    2.
                    <a
                        href="https://www.ft.com/content/fed37e59-1f36-453a-a9b1-70f94c8b24f9"
                        className={styles.citationLink}
                        target="_blank" 
                        rel="noopener noreferrer"
                    >
                        {' '}“Biodiversity Hit to Economies Estimated at up to $25tn a Year in Landmark Report.” 
                    </a>
                    The Financial Times, 17 Dec. 2024
                </p>
            </div>
        </div>
    );
};

export default HomePage;
