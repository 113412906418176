import React from 'react';
import styles from './ProductContainer.module.css';
import { Product } from '../../pages/Merch/data/products';
import LoadedImage from '../LoadedImage';


interface ProductContainerProps {
    product: Product;
    onClick?: (product: Product) => void;
    onAddToCart?: (product: Product) => void;
    showAddToCart?: boolean;
}

const ProductContainer: React.FC<ProductContainerProps> = ({
    product, 
    onClick,
    onAddToCart, 
    showAddToCart = true
}) => {

const handleCardClick = () => {
    if (onClick) {
        onClick(product);
    }
};


return (
    <div className={styles.productCard} onClick={handleCardClick}>
        <div className={styles.productContent}>
            <LoadedImage
                imgUrlMobile={product.reccoPhoto}
                imgUrlDesktop={product.imgUrl}
                alt={product.name}
                // Remove the flex justification for the image wrapper
                imageWrapperStyle={{
                position: 'relative',
                width: '100%',
                zIndex: 2
                }}
                imageStyle={{
                display: 'block',
                margin: '0 auto',
                maxWidth: '100%',
                height: 'auto',
                marginBottom: '1em',
                borderRadius: '0.25rem'
                }}
                spinnerBorderColor="#DBFF80"
                spinnerTopColor="#003600"
            />
            <h2 className={styles.productName}>{product.name}</h2>
            <p className={styles.productDesc}>{product.description}</p>
            <p className={styles.productPrice}>${(product.priceInCents / 100).toFixed(0)}</p>
            {product.shipDate && (
                <p className={styles.productShipDate}>{product.shipDate}</p>
            )}
            {showAddToCart && (
                <button
                className={styles.buyButton}
                onClick={() => onClick?.(product)}
                >
                Add to Cart
                </button>
            )}
        </div>
    </div>
    );
    
};

export default ProductContainer;