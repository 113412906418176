import React from 'react';
import LoadedImage from '../LoadedImage';

interface CircularPhotoProps {
    imgUrlMobile: string;
    imgUrlDesktop: string;
    altText: string;
}


const CircularPhoto: React.FC<CircularPhotoProps> = ({
    imgUrlMobile,
    imgUrlDesktop,
    altText,
}) => {
    return (
        <>
            <LoadedImage
                imgUrlMobile={imgUrlMobile}
                imgUrlDesktop={imgUrlDesktop}
                alt={altText}
                imageWrapperStyle={{ 
                    flex: '1',                             
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingRight: '0%',
                }}
                imageStyle={{ 
                    width: '100%',
                    maxWidth: '80%',
                    height: 'auto', 
                    borderRadius: '50%', 
                    objectFit: 'cover' 
                }}
            />
        </>
    );
};

export default CircularPhoto;