import React from 'react';
import styles from './LeftSkewHeader.module.css';

interface LeftSkewHeaderProps {
    text: React.ReactNode;
    textColor?: string;
}

const LeftSkewHeader: React.FC<LeftSkewHeaderProps> = ({
    text,
    textColor = '#003600',
})  => {
    
    return (
        <>
            <h1
                className={styles.leftHeader}
                style={
                    {
                        '--text-color': textColor
                    } as React.CSSProperties
                } 
            >
                {text}
            </h1>
        </>
    );
};

export default LeftSkewHeader;