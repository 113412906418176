import React, { useState } from 'react';
import styles from './TurtlTracker.module.css';
import TextInput from '../../components/TextInput';
import FilledSquareButton from '../../components/FilledSquareButton';
import { InterestedEmail } from '../../data/models/interestedEmail';
import { sendPutRequest } from '../../services/api/coreApiService';
import axios from 'axios';


const TurtlTracker: React.FC = () => {
    const[email, setEmail] = useState('');
    const[isLoading, setIsLoading] = useState(false);

    // TODO: Centralize this logic across this page and the footer!!
    const isValidEmail = (email: string): boolean => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    const handleSubscribeClick = async () => {
        if (isValidEmail(email)) {
            setIsLoading(true);

            const emailData = new InterestedEmail(email);
            const body = JSON.stringify(emailData);

            try {
                const resp = await sendPutRequest('Interested/beta', body);
                console.log('Response from API:', resp);

                alert("Success! We've signed you up for Turtle Mail! You should receive confirmation in your inbox soon!");
            } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                const statusCode = error.response.status;
                console.log('error response status turtl tracker beta page', error.response.status);

                if (statusCode === 409) {
                    alert("Looks like we've already got you signed up! If you haven't received our confirmation, please reach out to info@turtltracker.com for support!");
                } else if (statusCode === 500) {
                    alert("We're having some technical difficulties on our end getting you signed up. Please try again later, or reach out to info@turtltracker.com for support if the issue persists.");
                } else {
                    alert('Failed to subscribe. Please try again later.');
                }
            } else if (axios.isAxiosError(error) && error.request) {
              // The request was made but no response was received
                console.error('No response received:', error.request);
                alert('No response from server. Please check your network connection and try again.');
            } else {
                // Something happened in setting up the request
                console.error('Error setting up request:', error);
                alert('An unexpected error occurred. Please try again later.');
            }
        } finally {
            setIsLoading(false);
        }
        } else {
            alert('Please enter a valid email address.');
        }
    };

    return (
        <div className={styles.container}>
            <h2 className={styles.contentHeader}>
                Be the first to try TurtlTracker. Our beta launches spring of 2025.
            </h2>
            <p className={styles.betaDescription}>
                This spring, we’re accepting applications in Minnesota. Not local?
                Don’t worry—let us know you’re interested. We’ll soon expand
                to more turtle-loving cities!
            </p>
            <div className={styles.subscriptionContainer}>
                <TextInput
                    placeholder='Email'
                    borderColor='#003600'
                    textColor='#003600'
                    backgroundColor='#FAFAF2'
                    fontFamily="'Roboto', sans-serif"
                    className={styles.textInput}
                    value={email}
                    onChange={handleEmailChange}
                />
                <FilledSquareButton
                    text="Sign up"
                    backgroundColor='#003600'
                    textColor='#DBFF80'
                    fontFamily="'Roboto', sans-serif"
                    onClick={handleSubscribeClick}
                    disabled={isLoading}
                />
            </div>
        </div>
    );
};

export default TurtlTracker;