import React from 'react';
import { useCart } from '../../contexts/Merch/CartContext';
import ProductContainer from '../../components/ProductContainer';
import styles from './MerchCart.module.css';
import { loadStripe } from '@stripe/stripe-js';
import { sendPostRequest } from '../../services/api/coreApiService';

const stripePromise = loadStripe('pk_live_51QS8AVFN7W3wvsvNrGpZS5cKhmmKFky1bnoiIbnl82Yc4ItKIkB5BG1V1eJajkCaj7LE84ujKeI4SBI8v6kxdhN600z0m4nWFh');

const CartPage: React.FC = () => {
    const { 
        cartItems, 
        clearCart,
        updateItemQuantity,
        removeFromCart 
    } = useCart();

    const handleCheckout = async () => {
        if (cartItems.length === 0) return;

        const payload = {
            lineItems: cartItems.map(item => ({
                ProductName: item.name, 
                AmountInCents: item.priceInCents,
                Quantity: item.quantity,
            }))
        };

        const endpoint = "api/Payment/create-multi-item-checkout-session";
        const response = await sendPostRequest(endpoint, JSON.stringify(payload));
        console.log('Stripe checkout session response', JSON.stringify(response));

        const data = response.data;
        const stripe = await stripePromise;

        if (stripe && data.id) {
            const { error } = await stripe.redirectToCheckout({ sessionId: data.id });
            if (error) {
                console.error(error);
            } else {
                clearCart();
            }
        }
    };

    const totalCents = cartItems.reduce((sum, item) => 
        sum + item.priceInCents * item.quantity, 0);

    const totalCentsWithShipping = totalCents + 595;

    const totalDollars = (totalCentsWithShipping / 100).toFixed(2);

    return (
        <div className={styles.container}>
            <h1 className={styles.merchHeader}>
                Your Cart
            </h1>
            {cartItems.length === 0 ? (
                <p className={styles.emptyCartDesc}>
                    Your cart is empty.
                </p>
            ) : (
                <>
                    <div className={styles.productGrid}>
                        {cartItems.map(item => (
                            <div key={item.id} className={styles.productCard}>
                                <ProductContainer
                                    product={item}
                                    showAddToCart={false}
                                />
                                <div className={styles.quantityRow}>
                                    <label htmlFor={`quantity=${item.id}`}>
                                        Quantity:
                                    </label>
                                    <select
                                        id={`quantity=${item.id}`}
                                        value={item.quantity}
                                        onChange={(e) => 
                                            updateItemQuantity(item.id, parseInt(e.target.value))}
                                    >
                                        {Array.from({ length: 5 }, (_, i) => i + 1).map((num) => (
                                            <option key={num} value={num}>
                                                {num}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <button
                                    className={styles.removeButton}
                                    onClick={() => removeFromCart(item.id)}
                                >
                                    Remove
                                </button>
                            </div>
                        ))}
                    </div>
                    <div className={styles.totalContainer}>
                        <h2 className={styles.totalHeader}>   
                            Total: ${totalDollars} ($5.95 shipping)
                        </h2>
                        <div className={styles.checkoutButtonContainer}>
                            <button
                                className={styles.checkoutButton}
                                onClick={handleCheckout}
                            >
                                Checkout
                            </button>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
};

export default CartPage;