import React from 'react';
import styles from './IdentityCard.module.css';
import EmailHeader from '../EmailHeader';
import LoadedImage from '../LoadedImage';

interface IdentityCardProps {
    imgUrl: string;
    name: string;
    titleOne: string;
    titleTwo: string;
    email: string;
}

const IdentityCard: React.FC<IdentityCardProps> = ({ 
    imgUrl, 
    name, 
    titleOne,
    titleTwo, 
    email,
}) => {
    return (
        <div className={styles.identityCard}>
            <div className={styles.imageContainer}>
                <LoadedImage
                    imgUrlMobile={imgUrl}
                    imgUrlDesktop={imgUrl}
                    alt='profile picture'
                    imageWrapperStyle={{ 
                        flex: '1',                             
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        paddingRight: '0%',
                    }}
                    imageStyle={{ 
                        width: '100%',
                        maxWidth: '80%',
                        height: 'auto', 
                        borderRadius: '50%', 
                        objectFit: 'cover' 
                }}
                />
        </div>
        <div className={styles.contactInfo}>
            <h3 className={styles.name}>
                {name}
            </h3>
            <h3 className={styles.name}>
                {titleOne}
            </h3>
            <h3 className={styles.name}>
                {titleTwo}
            </h3>
            <EmailHeader
                email={email}
            />
        </div>
    </div>
    );
};

export default IdentityCard;
