import React from 'react';
import Hooray from './assets/Turtle-Hooray.png';
import Logo from './assets/TurtlTracker-Vertical-DarkGreen.png';
import styles from './MerchSuccess.module.css';

const MerchSuccess: React.FC = () => {
    return (
        <div className={styles.container}>
            <h1 className={styles.h1}>
                Thank You!
            </h1>
            <p className={styles.thankYouNote}>
                Thank you for your purchase! Your contribution directly supports our mission to protect turtles, reduce road mortality, 
                and promote safe crossings. 
                We deeply appreciate your generosity. 
                
            </p>
            <p className={styles.thankYouNote}>
                Love, TurtlTracker
            </p>
        </div>
    );
};

export default MerchSuccess;