import React from 'react';
import CircularPhoto from '../../components/CircularPhoto';
import PhotoDetail from '../../components/PhotoDetail';
import styles from './InfoCardImage.module.css';

interface InfoCardImageProps {
    headerText: string;
    descriptionText: string;
    imgUrlMobile: string;
    imgUrlDesktop: string;
    altText: string;
    headerTextColor?: string;
    descriptionTextColor?: string;
    backgroundColor?: string;
}

const InfoCardImage: React.FC<InfoCardImageProps> = ({
    headerText, 
    descriptionText,
    imgUrlMobile,
    imgUrlDesktop,
    altText,
    headerTextColor = "#003600",
    descriptionTextColor = "#003600",
    backgroundColor = '#FAFAF2',
}) => {
    return (
        <div 
            className={styles.infoCardContainer}
            style={
                {
                    '--background-color': backgroundColor,
                } as React.CSSProperties
            }
        >
            <div
                className={styles.imageContainer}
            >
                <CircularPhoto
                    imgUrlMobile={imgUrlMobile}
                    imgUrlDesktop={imgUrlDesktop}
                    altText={altText}
                />
            </div>
            <div
                className={styles.textContainer}
            >
                <PhotoDetail
                    headerText={headerText}
                    descriptionText={descriptionText}
                    headerTextColor={headerTextColor}
                    descriptionTextColor={descriptionTextColor}
                />
            </div>
        </div>
    );
};

export default InfoCardImage;