import React from 'react';

// TODO: PICKUP 1.19.2025: FINISH ADDING THE CIRCULAR ARROW BUTTON TO SIGN UP FOR BETA, CONTINUE LOOKING INTO SECRETS AND VIDEO ISSUE THING (FOR BOTTOM VIDEO)
const ArrowRightIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            height="48px"
            viewBox="0 -960 960 960"
            width="48px"
            fill="currentColor"
            {...props}
        >
            <path d="m560-242-43-42 168-168H160v-60h525L516-681l43-42 241 241-240 240Z"/>
        </svg>
    );
};

export default ArrowRightIcon;