interface Product {
    id: string;
    name: string;
    description: string;
    priceInCents: number;
    imgUrl: string;
    quantity: number;
    reccoPhoto: string; 
    shipDate?: string;
    sizes?: string[];
}

class Product implements Product {
    id: string;
    name: string;
    description: string;
    priceInCents: number;
    imgUrl: string;
    quantity: number;
    reccoPhoto: string; 
    shipDate?: string;
    sizes?: string[];

    constructor(
        id: string,
        name: string,
        description: string,
        priceInCents: number,
        imgUrl: string,
        quantity: number,
        reccoPhoto: string, 
        shipDate?: string,
        sizes?: string[]
) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.priceInCents = priceInCents;
        this.imgUrl = imgUrl;
        this.quantity = quantity;
        this.reccoPhoto = reccoPhoto;
        this.shipDate = shipDate;
        this.sizes = sizes;
    }
}

const GREEN_CREW = new Product(
    'prod_ROA39UFFrPsUOy',
    'EMBROIDERED CLASSIC CREW',
    'Pond slider green',
    5500,
    'https://turtl-web-images.azureedge.net/website/green-crew-3.jpg',
    1,
    'https://turtl-web-images.azureedge.net/website/sweatshirt-green-mobile.jpg',
    undefined,
    ['S', 'M', 'L', 'XL', 'XXL'],
); 

const EGGSHELL_CREW = new Product(
    'prod_ROA0rvO0Q0yXAi',
    'EMBROIDERED CLASSIC CREW',
    'Eggshell',
    5500,
    'https://turtl-web-images.azureedge.net/website/eggshell-crew-3.jpg',
    1,
    'https://turtl-web-images.azureedge.net/website/sweatshirt-eggshell-mobile.jpg',
    undefined,
    ['S', 'M', 'L', 'XL', 'XXL'],
);

const WHEAT_HAT = new Product(
    'prod_RPGHVBvdsSDAPO',
    'TURTLTRACKER X LOCALE',
    'Daily Pine beanie, wheat',
    3500,
    'https://turtl-web-images.azureedge.net/website/wheat-hat-4.jpg',
    1,
    'https://turtl-web-images.azureedge.net/website/hat-wheat-mobile.jpg',
    'SHIPS LATE JANUARY',
    ['Standard, one size fits all'],
);

const SAGE_HAT = new Product(
    'prod_RPGGi55hXAWWs7',
    'TURTLTRACKER X LOCALE',
    'Highline beanie, sage',
    3500,
    'https://turtl-web-images.azureedge.net/website/sage-hat-4.jpg',
    1,
    'https://turtl-web-images.azureedge.net/website/hat-sage-mobile.jpg',
    'SHIPS LATE JANUARY',
    ['Standard, one size fits all'],
);

const WHITE_HAT = new Product(
    'prod_RPG79AY9CfAPyn',
    'TURTLTRACKER X LOCALE',
    'Frosted Slife beanie, white',
    4500,
    'https://turtl-web-images.azureedge.net/website/white-hat-3.jpg',
    1,
    'https://turtl-web-images.azureedge.net/website/hat-white-mobile.jpg',
    'SHIPS LATE JANUARY',
    ['Standard, one size fits all'],
);

const BLACK_HAT = new Product(
    'prod_RPGAL8SZp7lIM8',
    'TURTLTRACKER X LOCALE',
    'Frosted Slife beanie, black',
    4500,
    'https://turtl-web-images.azureedge.net/website/black-hat-3.jpg',
    1,
    'https://turtl-web-images.azureedge.net/website/hat-black-mobile.jpg',
    'SHIPS LATE JANUARY',
    ['Standard, one size fits all'],
);

const PRODUCTS: Product[] = [
    GREEN_CREW,
    EGGSHELL_CREW,
    WHEAT_HAT,
    SAGE_HAT,
    WHITE_HAT,
    BLACK_HAT,
];

export { GREEN_CREW, EGGSHELL_CREW, WHEAT_HAT, SAGE_HAT, WHITE_HAT, BLACK_HAT };
export type { Product };
export default PRODUCTS;