import React, { useEffect } from 'react';
import styles from './MerchSuccess.module.css';
import { useCart } from '../../contexts/Merch/CartContext';
import { sendGetRequest } from '../../services/api/coreApiService';

const MerchSuccess: React.FC = () => {

    const { clearCart } = useCart();    

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);

        console.log('session params', params);
        const sessionId = params.get('session_id');
        console.log('session id', sessionId);

        const verifyPaymentStatus = async (checkoutSessionId: string) => {
            try {
                const path = `api/Payment/verify-checkout-session?sessionId=${checkoutSessionId}`;
                const response = await sendGetRequest(path);
                const isPaid = response.isPaid;
    
                console.log('response from verifyPayment', JSON.stringify(response));
                console.log('data from verifyPayment response', JSON.stringify(isPaid));
    
                if (isPaid) {
                    clearCart();
                }
            } catch(error) {
                console.error('Error verifying payment status', error);
            }
        };

        if (sessionId) {
            verifyPaymentStatus(sessionId);
        }
    }, [clearCart]);

    return (
        <div className={styles.container}>
            <h1 className={styles.h1}>
                Thank You!
            </h1>
            <p className={styles.thankYouNote}>
                Thank you for your purchase! Your contribution directly supports our mission to protect turtles, reduce road mortality, 
                and promote safe crossings. 
                We deeply appreciate your generosity. 
                
            </p>
            <p className={styles.thankYouNote}>
                Love, TurtlTracker
            </p>
        </div>
    );
};

export default MerchSuccess;