import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/Home';
import AboutUs from './pages/AboutUs';
import HowToHelp from './pages/HowToHelp';
import Merch from './pages/Merch';
import Contact from './pages/Contact';
import TurtlTracker from './pages/TurtlTracker';
import styles from './App.module.css';
import { Helmet } from 'react-helmet';
import MerchSuccess from './pages/MerchSuccess';
import MerchCancel from './pages/MerchCancel';
import { CartProvider } from './contexts/Merch/CartContext';
import MerchCart from './pages/MerchCart';
import MerchItem from './pages/MerchItem';
import Banner from './components/Banner';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Helmet>
        <title>TurtlTracker</title>
        <link rel="icon" type="image/png" href="/Turtle-Icon-DarkGreen.png" sizes="16x16" />
      </Helmet>
      <div className={styles['page-container']}>
      <CartProvider>
      
        <Header />
        <div className={styles['content-wrap']}>
          <main className="content">
            <Routes>
              <Route 
                path="/" element={
                  <>
                    <Banner
                        messages={[
                          { text: "Donate today.", url: "/how-to-help"},
                          { text: "Do it for the turtles!" },
                          { text: "Buy our merch.", url: "/merch"},
                        ]}
                        imageSrc="https://turtl-web-images.azureedge.net/website/Banner-Turtle.png"
                      />
                      <HomePage />                    
                  </>
                } 
              />
              <Route path="/how-to-help" element={<HowToHelp />} />
              <Route path="/merch" element={<Merch />} />
              <Route path="/merch/cart" element={<MerchCart />}/>
              <Route path="/merch/item/:productId" element={<MerchItem />}/>
              <Route path="/merch/success" element={<MerchSuccess />}/>
              <Route path="/merch/cancel" element={<MerchCancel />}/>
              <Route 
                path="/contact"
                element={
                  <>
                    <Banner
                      messages={[
                        { text: "Donate today.", url: "/how-to-help"},
                        { text: "Do it for the turtles!" },
                        { text: "Buy our merch.", url: "/merch"},
                      ]}
                      imageSrc="https://turtl-web-images.azureedge.net/website/Banner-Turtle.png"
                    />
                    <Contact />
                  </>
                } 
              />
              <Route 
                path="/turtltracker" 
                element={
                    <TurtlTracker />
                } 
              />
              <Route 
                path="/about-us"
                element={
                  <>
                    <Banner
                      messages={[
                        { text: "Donate today.", url: "/how-to-help"},
                        { text: "Do it for the turtles!" },
                        { text: "Buy our merch.", url: "/merch"},
                      ]}
                      imageSrc="https://turtl-web-images.azureedge.net/website/Banner-Turtle.png"
                    />
                    <AboutUs />
                  </>
                } 
              />
            </Routes>
          </main>
        </div>
        <Footer />
      </CartProvider>
      </div>
    </BrowserRouter>
  );
};

export default App;
