import React from 'react';
import styles from './VideoBox.module.css';

interface VideoBoxProps {
    video: string;
    altText?: string;
    overlayText?: React.ReactNode;
}

const VideoBox: React.FC<VideoBoxProps> = ({ 
    video, 
    altText,  
    overlayText,
}) => {
    
    return (
        <div className={styles.videoContainer}>
            <video 
                className={styles.video}
                src={video}
                autoPlay
                loop 
                playsInline
                muted
                aria-label={altText || 'Video content'}
            >
                {altText || 'Your browser does not support the video tag.'}
            </video>

            {/* Text overlay (if provided) */}
            {
                overlayText && (
                    <h1
                        className={styles.overlayText}
                    >
                        {overlayText}
                    </h1>
            )}
        </div>
    );
};

export default VideoBox;    

