import React from 'react';
import styles from './MerchCancel.module.css';

const MerchCancel: React.FC = () => {
    return (
        <div className={styles.container}>
            <h1 className={styles.h1}>
                Changed your mind?
            </h1>
            <p className={styles.thankYouNote}>
                Thank you for your intention to purchase TurtlTracker merch and in doing so support our mission to protect turtle species. 
                Unfortunately, we encountered an issue processing your purchase. 
                We encourage you to try again by visiting our merch page. 
                If you continue to experience issues, please contact us at inquiries@turtltracker.com
                so we can assist you in completing your purchase.
                
            </p>
            <p className={styles.thankYouNote}>
                Love, TurtlTracker
            </p>
        </div>
    );
};

export default MerchCancel;