import React from 'react';
import styles from './PlatformCard.module.css';
import CircularPhoto from '../../components/CircularPhoto';
import LeftSkewPar from '../../components/LeftSkewPar';


interface PlatformCardProps {
    imgUrlDesktop: string;
    imgUrlMobile: string;
    altText: string;
    title: string;
    description: string;
    headerTextColor?: string;
    descriptionTextColor?: string;
    dividerLineColor?: string;
}

const PlatformCard: React.FC<PlatformCardProps> = ({
    imgUrlDesktop,
    imgUrlMobile,
    altText,
    title, 
    description, 
    headerTextColor = '#FAFAF2',
    descriptionTextColor = '#FAFAF2',
    dividerLineColor = '#DBFF80',
}) => {
    return (
        <div 
            className={styles.platformCardContainer}
        >
            <CircularPhoto
                imgUrlDesktop={imgUrlDesktop}
                imgUrlMobile={imgUrlMobile}
                altText={altText}
            />
            <h2
                className={styles.name}
                style={
                    {
                        '--header-color': headerTextColor
                    } as React.CSSProperties
                }
            >
                {title}
            </h2>
            <div 
                className={styles.line}
                style={
                    {
                        '--div-line-color': dividerLineColor
                    } as React.CSSProperties
                }
            />
            <div>
                <LeftSkewPar
                    text={description}
                    textColor={descriptionTextColor}
                />
            </div>
        </div>
    );
};

export default PlatformCard;