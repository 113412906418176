import { TabItem }  from '../../../components/TabMenu';
import React from 'react';

const TAB_1 = new TabItem(
    "Turtles",
    "#turtles",
    "Turtles",
    "We are a voice for wildlife, advocating for their protection through a data-driven approach and dedicating ourselves to preserving their future.",
);

const TAB_2 = new TabItem(
    "Individuals",
    "#individuals",
    "Individuals",
    "We offer a free, fun and simple way to make an impact, join a like-minded community, and see the difference you’re making for our planet’s biodiversity",
);

const TAB_3 = new TabItem(
    "Non Profits",
    "#non-profits",
    "Nonprofits",
    "We collaborate with trusted non-profits and provide verified, data-driven insights to support their vital conservation efforts. Additionally, we strive to elevate and celebrate ecological voices through our platform.",
);

const TAB_4 = new TabItem(
    "Businesses",
    "#businesses",
    "Businesses",    
        <>
            Biodiversity loss costs the global economy up to $25 trillion annually—nearly a quarter of global GDP. Timely, accurate data is key to driving behavior change that enables both economies and ecosystems to thrive.
            <sup>
                2
            </sup>
        </>
);

const TAB_5 = new TabItem(
    "The Planet",
    "#theplanet",
    "ThePlanet",
    "Our mission is to shift society’s ‘ego-system’ mindset into a true ecosystem, grounded in the belief that all life is connected and deserving of safe passage"
)

const TABS_DATA = [
    TAB_1,
    TAB_2,
    TAB_3, 
    TAB_4,
    TAB_5,
];

export default TABS_DATA; 
