import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styles from './MerchItem.module.css';

import PRODUCTS, { Product } from '../../pages/Merch/data/products';
import productRecoMap from '../Merch/data/productRecoMap';
import { useCart } from '../../contexts/Merch/CartContext';
import AddToCartModal from '../../components/AddToCartModal';

const MerchItem: React.FC = () => {
    const { productId } = useParams();
    const navigate = useNavigate();

    const { addToCart } = useCart();

    const [showModal, setShowModal] = useState(false);
    const [selectedSize, setSelectedSize] = useState<string | null>(null);
    const [recommendedItems, setRecommendedItems] = useState<Product[]>([]);

    const product = PRODUCTS.find((p) => p.id === productId);

    if (!product) {
        return <div className={styles.notFound}>
                    Product not found.
                </div>;
    }

    const availableSizes = product.sizes;

    const handleAddToCat = () => {
        if (!selectedSize) {
            alert('Please select a size');
            return;
        }

        addToCart(product);

        const recs = productRecoMap.get(product.id) || [];
        setRecommendedItems(recs);
        setShowModal(true);
    };
    
    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleCheckout = () => {
        navigate('/merch/cart');
    };

    return (
        <div
            className={styles.pageContainer}
        >
            {/* LEFT SIDE: PRODUCT IMAGES*/}
            <div
                className={styles.imageContainer}
            >
                <img
                    src={product.reccoPhoto}
                    alt={product.name}
                    className={styles.mainImage}
                />
            </div>

            {/* Right Side: Product Details */}
            <div
                className={styles.detailsContainer}
            >
                <h1 
                    className={styles.productTitle}
                >
                    {product.name} 
                </h1>
                <p 
                    className={styles.productPrice}
                >
                    ${ (product.priceInCents / 100).toFixed(2) }
                </p>
                
                {/* Sizes, consider colors later */}
                <div
                    className={styles.optionSection}
                >
                    <label
                        className={styles.optionLabel}
                    >
                        Select Size:
                    </label>
                    <div className={styles.sizeOptions}>
                        {availableSizes?.map((size) => (
                            <button
                                key={size}
                                className={
                                    size === selectedSize ? styles.sizeSelected : styles.sizeButton
                                }
                                onClick={() => setSelectedSize(size)}
                            >
                                {size}
                            </button>
                        ))}
                    </div>
                </div>

                {/* Add to Cart */}
                <button
                    className={styles.addToBagButton}
                    onClick={handleAddToCat}
                >
                    Add to Bag
                </button>
            </div>
                <AddToCartModal
                    isOpen={showModal}
                    similarProducts={recommendedItems}
                    onClose={handleCloseModal}
                    onCheckout={handleCheckout}
                />
        </div>
    );
};

export default MerchItem;