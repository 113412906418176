import React, { useState } from 'react';
import styles from './HowToHelp.module.css';
import { Elements } from '@stripe/react-stripe-js';
import { Appearance, loadStripe } from '@stripe/stripe-js';
import PaymentForm from '../../components/PaymentForm';
import SquareButton from '../../components/SquareButton';
import { sendPostRequest } from '../../services/api/coreApiService';
import CircularButton from '../../components/CircularButton';
import { Link } from 'react-router-dom';
import EmailInline from '../../components/EmailInline';

const stripePromise = loadStripe('pk_live_51QS8AVFN7W3wvsvNrGpZS5cKhmmKFky1bnoiIbnl82Yc4ItKIkB5BG1V1eJajkCaj7LE84ujKeI4SBI8v6kxdhN600z0m4nWFh');

const presetAmounts = [25, 50, 75, 100, 250, 500, 1000, 5000, 10000];
const HowToHelp: React.FC = () => {

    const [showPaymentForm, setShowPaymentForm] = useState<boolean>(false);
    const [amountToDonate, setAmountToDonate] = useState<number | null>(null);  
    const [selectedAmount, setSelectedAmount] = useState<number | null>(null);
    const [customAmount, setCustomAmount] = useState<string>('');
    const [clientSecret, setClientSecret] = useState<string | null>(null);
    const [showCustomInput, setShowCustomInput] = useState<boolean>(false);

    const handleAmountClick = (amount: number) => {
        setSelectedAmount(amount);
        setCustomAmount('');
        setShowCustomInput(false);
    };

    const handleCustomClick = () => {
        setSelectedAmount(null);
        setShowCustomInput(true);
    }

    const handleCustomAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCustomAmount(e.target.value);
    };

    const handleDonate = async () => {
        let amount = selectedAmount;

        if (customAmount) {
            const parsed = parseFloat(customAmount);    
            if (!isNaN(parsed)) {
                amount = parsed;
            }
        }

        if (amount && amount > 0) {
            setAmountToDonate(amount);

            const body = JSON.stringify(amount);
            const response = await sendPostRequest('api/Payment/create-payment-intent', {
                amount: body,
            });

            const data = response.data;
            setClientSecret(data.clientSecret);
            setShowPaymentForm(true);
        } else {
            alert('Please enter a valid donation amount.');
        }
    };

    const appearance: Appearance = {
        theme: 'stripe',
        variables: {
            colorPrimary: '#003600',
            colorBackground: '#FAFAF2',
            colorText: '#003600',
            colorDanger: '#FF1000'
        },
        rules: {
            '.Input': {
                fontFamily: 'Work Sans, sans-serif',
            },
            '.Label': {
                fontFamily: 'Work Sans, sans-serif',
            },  
            '.Text': {
                fontFamily: 'Work Sans, sans-serif',
            },
            '.Tab': {
                fontFamily: 'Work Sans, sans-serif',
            },
            '.SubmitButton': {
                fontFamily: 'Work Sans, sans-serif',
                backgroundColor: '#003600',
                border: '1px solid #DBFF80',
                borderRadius: '0.5rem',
                color: '#DBFF80',
                fontSize: '1rem',
                padding: '0.5em 1em',
                cursor: 'pointer',
                transition: 'all 0.3s ease'
            },
            '.SubmitButton:hover': {
                backgroundColor: '#DBFF80',
                color: '#003600'
            },
            '.SubmitButton:disabled': {
                opacity: '0.6',
                cursor: 'not-allowed'
            }
        }
    };

    return (
        <div>
            <div className={styles.howToHelpContainer}>
                <h1 className={styles.howToHelpHeader}>
                    How to help
                </h1>
                <p className={styles.howToHelpDescription}>
                    At TurtlTracker, inclusivity is at the core of everything we do.
                    We believe the key to solving this crisis lies in community
                    action. That’s why we’re making our app completely free of
                    charge, so that anyone with a phone and a heart for protecting 
                    our environment can contribute to the cause.
                </p>
                <p className={styles.howToHelpDescription}>
                    Launching and maintaining this platform comes with
                    significant financial challenges, including infrastructure and
                    equipment costs, software subscriptions, and development
                    support. As a self-funded initiative, we welcome and deeply
                    appreciate any support, especially in the following forms:
                </p>
            </div>
        <div className={styles.donationContainer}>
        {!showPaymentForm ? (
                <>
                    <h2 className={styles.donateSubHeader}>
                        Donate
                    </h2>
                        <p className={styles.donationDescription}>
                            Every Turtl philanthropist will recieve a special thank-you from
                            TurtlTracker, and a whole lot of good karma. 
                        </p>
                    <div className={styles.circularButtonContainer}>
                        {presetAmounts.map((amount) => (
                                <CircularButton
                                    key={amount}
                                    label={amount}
                                    backgroundColor="#003600"
                                    textColor="#DBFF80"
                                    selected={selectedAmount === amount}
                                    selectedBackgroundColor="#DBFF80"
                                    selectedTextColor="#003600"
                                    onClick={() => handleAmountClick(amount)}
                                />  
                            ))}
                            <div className={styles.customAmountWrapper}>
                            <CircularButton
                                label="Custom"
                                backgroundColor="#FF1000"
                                textColor="#FAFAF2"
                                selected={!!customAmount && !selectedAmount}
                                selectedBackgroundColor="#FAFAF2"
                                selectedTextColor="#FF1000"
                                onClick={handleCustomClick}
                            />
                            {showCustomInput && (
                                <input
                                    type="number"
                                    placeholder="Amount"
                                    className={styles.customAmountInput}
                                    value={customAmount}
                                    onChange={handleCustomAmountChange}
                                    style={{ borderColor: '#003600'}}
                                />
                            )}
                        </div>
                    </div>
                    <div
                        className={styles.buttonContainer}
                    >
                        <SquareButton
                            text="Donate"
                            onClick={handleDonate}
                            textColor="#DBFF80"
                            borderColor="#FAFAF2"
                            backgroundColor="#003600"
                            hoverTextColor="#003600"
                            hoverBackgroundColor="#DBFF80"
                        />
                    </div>
                </>
            ) : (
                <div>
                    <button
                        className={styles.backButton}
                        onClick={() => {
                            setShowPaymentForm(false);
                            setClientSecret(null);
                        }}
                    >
                        &larr; Back
                    </button>

                    {clientSecret && (
                        <Elements
                            stripe={stripePromise}
                            options={{
                                clientSecret: clientSecret,
                                appearance: appearance
                            }}
                        >
                            <div className={styles.confirmButtonContainer}>
                                <PaymentForm
                                    amount={amountToDonate!}
                                    clientSecret={clientSecret}
                                    onSuccess={() => {
                                        setShowPaymentForm(false);
                                        setClientSecret(null);
                                    }}
                                />
                            </div>
                        </Elements>
                    )}
                </div>
            )}
        </div>
            <div className={styles.getInvolvedContainer}>
                <h2 className={styles.getInvolvedSubheader}>
                        Get involved
                    </h2>
                    <p className={styles.donationDescription}>
                        Email us at <EmailInline
                                        email='inquiries@turtltracker.com'
                                        subject='I would like to get involved!'
                                        size='small'
                                        color='#003600'
                                    /> if any of the below (or any other ideas you may have!) are of interest to you.
                    </p>
                    <div className={styles.customListContainer}>
                        <ul className={styles.customList}>
                            <li>Are you a herpetologist, biologist, or road ecologist? Drop us a line, we would love to chat.</li>
                            <li>Invest and get in on the ground level. We are fiercely focused on turtles at the moment, but are building to scale.</li>
                            <li>Partner with us for our fundraising efforts. We are in search of local venues (especially one interested in brewing up a nice TurtlBeer with us) merch collabs, and local businesses willing to offer goods or services for raffles at our fundraising events.</li>
                            <li>Spread the word. If you are involved in or connected with the media, we would love to tell our story. Help us grow organically by engaging with our social media, and telling your friends and family.</li>
                            <li>
                                Sign up to beta test. You can find more information on that {' '} 
                                <Link to="/turtltracker" className={styles.link}>here.</Link>
                            </li>
                        </ul>
                    </div>
            </div>
        </div>
    );
};

export default HowToHelp;