import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Footer.module.css';
import TextInput from '../TextInput';
import FilledSquareButton from '../FilledSquareButton';
import Icon from '../Icon';
import IconButton from '../IconButton';
import ArrowRightIcon from '../ArrowRightIcon';
import InstaIcon from './assets/Insta.png';
import { sendPutRequest } from '../../services/api/coreApiService';
import { InterestedEmail } from '../../data/models/interestedEmail';
import axios from 'axios';

const Footer: React.FC = () => {
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    };

    const handleSubscribeClick = async () => {
        if (isValidEmail(email)) {
            setIsLoading(true);

            const emailData = new InterestedEmail(email);
            const body = JSON.stringify(emailData);

            try {
                const resp = await sendPutRequest('Interested/newsletter', body);
                console.log('Response from API:', resp);

                alert(
                    "Success! We've signed you up for Turtle Mail! You should receive confirmation in your inbox soon!"
                );

            } catch (error) {
            
                if (axios.isAxiosError(error) && error.response) {
                const statusCode = error.response.status;

                if (statusCode === 409) {
                alert(
                    "Looks like we've already got you signed up! If you haven't received our confirmation, please reach out to info@turtltracker.com for support!"
                );
                } else if (statusCode === 500) {
                alert(
                    "We're having some technical difficulties on our end getting you signed up. Please try again later, or reach out to info@turtltracker.com for support if the issue persists."
                );
                } else {
                alert('Failed to subscribe. Please try again later.');
                }
            } else if (axios.isAxiosError(error) && error.request) {
                console.error('No response received:', error.request);
                alert(
                'No response from server. Please check your network connection and try again.'
                );
            } else {
                console.error('Error setting up request:', error);
                alert('An unexpected error occurred. Please try again later.');
            }
            } finally {
            setIsLoading(false);
            }
        } else {
            alert('Please enter a valid email address.');
        }
    };

        return (
        <footer className={styles.footer}>
            <div className={styles['footer-container']}>

            {/* Left Section */}
            <div className={styles['left-section']}>
                <h2 className={styles['header-text']}>
                Turtle mail, straight to your inbox
                </h2>
                <p className={styles['sub-text']}>(one step up from snail mail)</p>

                <div className={styles['subscription-container']}>
                <TextInput
                    placeholder="Enter your email"
                    borderColor="#DBFF80"
                    textColor="#FAFAF2"
                    backgroundColor="transparent"
                    fontFamily="'Roboto', sans-serif"
                    className={styles.textInput}
                    value={email}
                    onChange={handleEmailChange}
                />
                <FilledSquareButton
                    text={isLoading ? 'Subscribing...' : 'Subscribe'}
                    backgroundColor="#DBFF80"
                    textColor="#003600"
                    hoverBackgroundColor='#FAFAF2'
                    hoverTextColor='#003600'
                    fontFamily="'Roboto', sans-serif"
                    onClick={handleSubscribeClick}
                    disabled={isLoading}
                />
                </div>

                {/* ICON BUTTON FOR MOBILE (hidden on desktop) */}
                <div className={styles['icon-button-mobile']}>
                    <IconButton
                        iconSrc={ArrowRightIcon}
                        iconAlt="Arrow Right"
                        text="Get TurtlTracker"
                        backgroundColor="#003600"
                        hoverBackgroundColor='#FAFAF2'
                        hoverTextColor='#003600'
                        textColor="#DBFF80"
                        fontFamily="'Work Sans', sans-serif"
                        onClick={() => navigate('/turtltracker')}
                    />
                </div>

                <p className={styles['copy-right']}>&copy; TurtlTracker LLP</p>
            </div>

            {/* Right Section */}
            <div className={styles['right-section']}>
                <div className={styles['social-icons']}>
                <Icon
                    src={InstaIcon}
                    alt="Instagram"
                    link="https://www.instagram.com/turtltracker"
                />
                </div>

                {/* ICON BUTTON FOR DESKTOP (hidden on mobile) */}
                <div className={styles['icon-button-desktop']}>
                <IconButton
                    iconSrc={ArrowRightIcon}
                    iconAlt="Arrow Right"
                    text="Get TurtlTracker"
                    backgroundColor="#003600"
                    textColor="#DBFF80"
                    hoverBackgroundColor='#FAFAF2'
                    hoverTextColor='#003600'
                    fontFamily="'visuelt-regular-pro', sans-serif"
                    onClick={() => navigate('/turtltracker')}
                />
                </div>
            </div>
            </div>
        </footer>
        );
};

export default Footer;
