import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { IoMdMenu, IoMdClose } from 'react-icons/io';
import { useCart } from '../../contexts/Merch/CartContext';
import styles from './Header.module.css';
import Logo from './assets/TurtlLogo.png';
import SquareLinkButton from '../SquareLinkButton';
import ShoppingCartIcon from '../ShoppingCartIcon';

const Header: React.FC = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const { cartItems } = useCart();

    const totalCartQuantity = cartItems.reduce(
    (total, item) => total + item.quantity,
    0
    );

    const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    };

    const closeMenu = () => {
    setMenuOpen(false);
    };

    return (
        <header className={styles.header}>
            <div className={styles.topbar}>
            {/* Logo on the left */}
            <Link to="/" className={styles.logoLink}>
                <img src={Logo} alt="Home" className={styles.logo} />
            </Link>

            {/* Navigation + Cart (Desktop) */}
            <nav className={styles.navContainer}>
                <div className={styles.simpleLinks}>
                <Link to="/about-us" className={styles.navItem}>About us</Link>
                <Link to="/how-to-help" className={styles.navItem}>How to Help</Link>
                <Link to="/merch" className={styles.navItem}>Merch</Link>
                <Link to="/contact" className={styles.navItem}>Contact</Link>
                </div>
                <div className={styles.buttonContainer}>
                <SquareLinkButton 
                    to="/turtltracker"
                    text="Get TurtlTracker"
                />
                </div>
                <Link
                    to="/merch/cart"
                    className={styles.cartWrapper}
                    aria-label="View cart"
                    >
                    <ShoppingCartIcon 
                        width='36px'
                        height='36px'
                        className={styles.cartIcon} 
                    />
                    {totalCartQuantity > 0 && (
                        <span className={styles.cartCount}>{totalCartQuantity}</span>
                    )}
                </Link>
            </nav>

            {/* Hamburger Icon (Mobile Only) */}
            <button
                className={styles.hamburgerButton}
                onClick={toggleMenu}
                aria-label="Toggle navigation menu"
            >
                <IoMdMenu className={styles.hamburgerIcon} />
            </button>
            </div>

            {/* Mobile Drawer */}
            {menuOpen && (
            <>
                <div className={styles.overlay} onClick={closeMenu} />
                <div className={styles.drawer}>
                {/* Logo on the left */}
                <div className={styles.drawerHeader}>
                    <div className={styles.logoContainerMobile}>
                        <Link
                            to="/"
                            onClick={closeMenu}
                            className={styles.drawerLogoLink}
                        >
                            <img
                                src="https://turtl-web-images.azureedge.net/website/TurtlTracker-Vertical-DarkGreen.png"
                                alt="TurtlTracker"
                                className={styles.drawerLogo}
                            />
                        </Link>
                    </div>
                    {/* Icons on the right */}
                    <div className={styles.iconGroup}>
                        <Link
                            to="/merch/cart"
                            onClick={closeMenu}
                            className={styles.drawerCartWrapper}
                            aria-label="View cart"
                        >
                            <ShoppingCartIcon 
                                fill='#003600'
                                height='30px'
                                width='30px'
                                className={styles.drawerCartIcon} 
                            />
                            {cartItems.length > 0 && (
                            <span className={styles.drawerCartCount}>
                                {cartItems.length}
                            </span>
                            )}
                        </Link>
                        <button
                            className={styles.closeButton}
                            onClick={closeMenu}
                            aria-label="Close navigation menu"
                        >
                            <IoMdClose />
                        </button>
                    </div>
                </div>

                <nav className={styles.navLinks}>
                    <Link to="/about-us" onClick={closeMenu}>About us</Link>
                    <Link to="/how-to-help" onClick={closeMenu}>How to Help</Link>
                    <Link to="/merch" onClick={closeMenu}>Merch</Link>
                    <Link to="/contact" onClick={closeMenu}>Contact</Link>
                    {/* On mobile, Get TurtlTracker as a link */}
                    <Link to="/turtltracker" onClick={closeMenu}>Get TurtlTracker</Link>
                </nav>
                </div>
            </>
            )}
        </header>
    );
};

export default Header;
